// Title
h1 {
  font-size: 72px;
}

// Login Section
.login-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/assets/loginbg.avif");
  background-repeat: no-repeat;
  background-size: cover;
}

.form-section {
  border-radius: 5px;
  font-family: sans-serif;
  line-height: 1.5;
  padding: 50px 50px 50px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
}

.form-control {
  height: 50px;
  &.search-input {
    height: 45px;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid #0d6efd;
  }
}

.custom-checkbox {
  position: relative;
  min-width: 150px;
  height: 45px;
  padding: 0;
  margin: 0 20px 20px 0;
  input {
    position: absolute;
    top: 50%;
    margin-left: 0 !important;
    float: inherit !important;
    left: 0;
    z-index: 1;
    font-size: 20px;
    margin-top: 0;
    transform: translate(-50%, -50%);
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-label {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-check-input:checked + .form-check-label {
    border: 1px solid #0d6efe !important;
    background-color: #e8f2fd;
  }
}

.primary-btn {
  padding: 5px 20px;
  height: 50px;
}

.form-btn {
  position: relative;
  margin-top: 30px;
}

// Title Section
.title-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #b7c4d3;

  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 4px;
    background: #364c5b;
    left: 0;
    bottom: -3px;
  }

  h2 {
    margin-bottom: 0;
  }
}

// Main Layout
.main-layout {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.main-section {
  position: relative;
  flex: 1;
}

.sidebar-section {
  position: relative;
  width: 280px;
  background: #e8f2fd;
  transition: 0.3s all ease;
  border-right: 10px solid #e8f2fd;
  opacity: 1;
  @media (max-width: 991.98px) {
    position: absolute;
    z-index: 10;
    height: 100%;
  }
  &.open-sidebar {
    width: 0;
    z-index: 0;
    transition: 0.3s all ease;
    border-right: 0px;
    opacity: 0;
  }
}

.wrap-container {
  position: relative;
  min-height: calc(100vh - 126px);
}

.page-section {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}

.data-section {
  position: relative;
  padding: 0px 10px;
}

.space-top {
  padding-top: 30px;
}
.space-bottom {
  padding-top: 30px;
}

// Data Table
.table-section {
}

.data-table-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 30px;

  h4 {
    position: relative;
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    color: #173886;
    padding-bottom: 20px;

    &::before {
      content: "";
      position: absolute;
      width: 110px;
      height: 3px;
      background: #f77263;
      bottom: -2px;
    }
  }
}

table {
  thead,
  tbody {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}

.d-table-btn {
  position: relative;
  min-width: 110px;
  margin: 0 7px;

  i {
    margin-right: 10px;
  }
}

.custom-pagination {
  position: relative;
  display: flex;
  justify-content: end;
}

.table-header-options {
  display: flex;
  flex-wrap: wrap;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
}

.details-card {
  position: relative;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 15px 15px;
  margin-bottom: 20px;
  .details-title {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-size: 18px;
    &::before {
      content: "";
      position: absolute;
      width: 60px;
      height: 3px;
      background: #f77263;
      bottom: -2px;
    }
  }
  .details-box {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 600;
      width: 130px;
    }
    p {
      flex: 1;
      font-size: 18px;
      margin-block: 0;
    }
  }
}
.details-sect {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .card-box {
    position: relative;
    width: calc(50% - 20px);
    background: #fafafa;
    padding: 15px 15px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 15px;
    @media (max-width: 576px) {
      width: calc(100%);
      margin-right: 00px;
    }
  }
}
