.footer-section {
  position: relative;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  border-top: 2px solid #e8f2fe;
}
