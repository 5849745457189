.navbar-section {
  min-height: 75px;
  border-bottom: 2px solid #e8f2fe;
  .menu-btn {
    i {
      font-size: 18px;
      line-height: 23px;
    }
  }
  .nav-header {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .custom-dropdown {
    .dropdown-toggle {
      white-space: nowrap;
      background: transparent;
      color: #222 !important;
      width: 160px;
      text-align: start;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ddd;
      &::after {
        display: none;
      }
    }
  }
}
