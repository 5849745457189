.sidebar-header {
  position: relative;
  width: 100%;
  height: 55px;
  border-bottom: 5px solid #296ffe;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ddd;
}
.sidebar-logo {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .logo-link {
    font-size: 26px;
    font-weight: 600;
    text-decoration: none;
  }
  .menu-btn {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translate(-50%, -50%);
    display: none;
    @media (max-width: 991.98px) {
      display: block;
    }
  }
}
.sidebar-menu-panel {
  background-color: white;
  position: relative;
  height: calc(100% - 75px);
  width: 100%;
  border-radius: 20px;
}
.menu-section {
  position: relative;
  margin: 0;
  padding: 30px 0 0 0;
  li {
    .menu-link {
      position: relative;
      width: 100%;
      text-decoration: none;
      font-size: 16px;
      padding: 10px 15px;
      display: block;
      margin-bottom: 5px;
      color: #222;
      font-weight: 500;
      i {
        margin-right: 5px;
      }
      label {
        position: relative;
        z-index: 1;
      }
      &.active,
      &:hover {
        background: #296ffe;
        color: #fff;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   background: #296ffe;
        //   width: 240px;
        //   height: 100%;
        //   z-index: 0;
        //   color: #222;
        //   border-top-right-radius: 10px;
        //   border-bottom-right-radius: 10px;
        // }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   right: 0;
        //   top: 0;
        //   background: #296ffe;
        //   width: 10px;
        //   height: 100%;
        //   z-index: 0;
        //   border-top-left-radius: 10px;
        //   border-bottom-left-radius: 10px;
        // }
      }
    }
  }
}
