.bread-crumb-sec {
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: rgba(41, 111, 254, 0.1);
  margin-top: 20px;
  margin-bottom: 25px;
  border-radius: 10px;
}
.breadcrumb {
  margin-bottom: 0;
}
.page-title {
  position: relative;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 600;
}
